.App {
    background-color: #ededed !important;
}

.form-container {
    .button__save {
        margin: 20px 0;
    }

    .date-selector {
        width: 100%;
        margin-top: 10px;
    }
}

.filters-container {
    display: flex;
}

.download-table-xls-button {
    border: 0;
    cursor: pointer;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    text-transform: uppercase;
    padding: 6px 16px;
    border-radius: 4px;
    color: #fff;
    background-color: #1976d2;
    margin-bottom: 20px;
    margin-left: 14px;
    vertical-align: top;
}

ul.MuiPagination-ul {
    justify-content: center;
}

.MuiDialogContent-root.dialog-dates.css-31xppo-MuiDialogContent-root {
    padding-top: 24px;
}
