.serial-container {
    text-align: center;
}

.serial-type {
    display: inline-block;
    vertical-align: top;
    margin: 0 25px;
    text-align: left;
    max-height: 600px;
    overflow: auto;
}

.title-serial {
    font-weight: 600;
    padding: 6px 16px;
}

.value-serial {
    margin: 5px 0;
}